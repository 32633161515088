import * as React from 'react';
import {useState} from 'react';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {EcsTasksIndex} from './EcsTasksIndex';
import {ecsTasksAPI} from 'api/ecs_tasks.js';
import {ErrorUtil} from 'utils/error-util.js';
import {sessionState} from 'session/session-state.js';

import {GlobalCss} from 'components/global-css.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {DateUtil} from 'utils/date-util.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

const EcsTasksConfirm = (props) => {
  const returnAction = () => {
    const params = {
      pathname: '/ecs_tasks',
    };
    props.history.push(params);
  };

  const [showProgress, setShowProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const initialState = props.location.state;
  if (!initialState) {
    returnAction();
    return <EcsTasksIndex/>;
  }

  const createParams = () => {
    return {
      'accountId': sessionState.accountId(),
      'taskName': initialState.taskName,
      'loopCount': initialState.loopCount,
      'notes': initialState.notes,
      'endDate': DateUtil.toMSec(initialState.endDate)
    };
  };

  const executeApi = () => {
    const action = ecsTasksAPI.execute(createParams());

    setShowProgress(true);
    action.catch((error) => {
      setShowProgress(false);
      const errorMessage = ErrorUtil.generateMessage(error);
      setErrorMessage(errorMessage);
      console.error(errorMessage);
    });

    setShowDialog(true);
    setTimeout(() => {
      setShowProgress(false);
      returnAction();
    }, 2000);
  };

  const prodWarningMessage = initialState.environment === '本番' ? '（本番環境のデータが更新されます）' : null;

  return (
    <div style={{marginLeft: '20px'}}>
      <h3>以下のECSタスクを実行しますか？<span style={{fontWeight: 'bold', color: 'red'}}>{prodWarningMessage}</span></h3>

      <Box style={{marginLeft: '40px'}}>
        <ErrorMessage errorMessage={errorMessage} />
      </Box>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        TransitionComponent={Slide}
        aria-labelledby="notification-dialog"
      >
        <DialogTitle id="notification-dialog">ECSタスクの実行を開始します。</DialogTitle>
      </Dialog>

      <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
        {showProgress ? <LinearProgress /> : null}
      </div>

      <div style={{marginLeft: '30px'}}>
        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 130, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>実行環境</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={initialState.environment}
              disabled={true}
              margin="none"
              style={{width: 120}}
            />
          </li>
        </ul>
        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 130, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>ECSタスク名称</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={initialState.taskName}
              disabled={true}
              margin="none"
              style={{width: 500}}
            />
          </li>
        </ul>

        {initialState.loopCount &&
          <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
            <li style={{width: 130, marginTop: 10}}>
              <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>実行回数</InputLabel>
            </li>
            <li style={{paddingTop: 5}}>
              <TextField
                type="text"
                required={true}
                placeholder=""
                value={initialState.loopCount}
                disabled={true}
                margin="none"
                style={{width: 120}}
              />
            </li>
          </ul>
        }

        {initialState.endDate &&
          <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
            <li style={{width: 130, marginTop: 10}}>
              <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>終了日</InputLabel>
            </li>
            <li style={{paddingTop: 5}}>
              <TextField
                type="text"
                required={true}
                placeholder=""
                value={DateUtil.toW3CFormat(initialState.endDate)}
                disabled={true}
                margin="none"
                style={{width: 120}}
              />
            </li>
          </ul>
        }

        <ul className={props.classes.flexContainer}>
          <li style={{width: 50, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>備考:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              value={initialState.notes}
              variant="outlined"
              disabled={true}
              margin="dense"
              fullWidth={true}
              className={props.classes.textAreaField}
              multiline
              rows={5}
              style={{width: 500}}
            />
          </li>
        </ul>

        <div style={{marginTop: '50px', marginLeft: '50px'}}>
          <ul className={props.classes.flexContainer}>
            <li style={{marginRight: '30px'}}>
              <Button style={{width: '100px'}} variant="contained" color="primary" onClick={() => executeApi()}>
                実行
              </Button>
            </li>
            <li>
              <Button variant="contained" color="secondary" onClick={() => returnAction()}>
                  キャンセル
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

EcsTasksConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(EcsTasksConfirm));
export {forExport as EcsTasksConfirm};
