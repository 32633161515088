import * as React from 'react';
import {useState} from 'react';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {MilkingSummaryIndex} from './MilkingSummaryIndex';
import {milkingSummaryAPI} from 'api/milking-summary.js';
import {DateUtil} from 'utils/date-util.js';
import {ErrorUtil} from 'utils/error-util.js';

import {GlobalCss} from 'components/global-css.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

const MilkingSummaryConfirm = (props) => {
  const [showProgress, setShowProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const initialState = props.location.state;

  const returnAction = (waitingJob = false) => {
    const params = {
      pathname: '/milking_summary',
      state: {
        waitingJob: waitingJob
      }
    };
    props.history.push(params);
  };

  if (!initialState) {
    returnAction();
    return <MilkingSummaryIndex/>;
  }

  const createParams = () => {
    return {
      'farmId': initialState.farm.id(),
      'fromDate': initialState.fromDate,
      'toDate': initialState.toDate
    };
  };

  const executeApi = () => {
    const action = milkingSummaryAPI.create(createParams());

    setShowProgress(true);
    action.then((res) => {
      setShowDialog(true);
      setTimeout(() => {
        setShowProgress(false);
        returnAction(true);
      }, 1000);
    }).catch((error) => {
      setShowProgress(false);
      const errorMessage = ErrorUtil.generateMessage(error);
      setErrorMessage(errorMessage);
      console.error(errorMessage);
    });
  };

  return (
    <div style={{marginLeft: '20px'}}>
      <h3>以下の内容で搾乳量の再集計を実行しますか？</h3>

      <Box style={{marginLeft: '40px'}}>
        <ErrorMessage errorMessage={errorMessage} />
      </Box>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        TransitionComponent={Slide}
        aria-labelledby="notification-dialog"
      >
        <DialogTitle id="notification-dialog">登録が完了しました</DialogTitle>
      </Dialog>

      <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
        {showProgress ? <LinearProgress /> : null}
      </div>

      <div style={{marginLeft: '30px'}}>
        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 80, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>牧場名:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={initialState.farm.data.farmName}
              disabled={true}
              margin="none"
              style={{width: 300}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 80, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>farm_id:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={initialState.farm.data.id}
              disabled={true}
              margin="none"
              style={{width: 300}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 120, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>再集計開始日:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={DateUtil.toW3CFormat(initialState.fromDate)}
              disabled={true}
              margin="none"
              style={{width: 120}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 120, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>再集計終了日:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={DateUtil.toW3CFormat(initialState.toDate)}
              disabled={true}
              margin="none"
              style={{width: 120}}
            />
          </li>
        </ul>

        <div style={{marginTop: '50px', marginLeft: '50px'}}>
          <ul className={props.classes.flexContainer}>
            <li style={{marginRight: '30px'}}>
              <Button style={{width: '100px'}} variant="contained" color="primary" onClick={() => executeApi()}>
                実行
              </Button>
            </li>
            <li>
              <Button variant="contained" color="secondary" onClick={() => returnAction()}>
                  キャンセル
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

MilkingSummaryConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(MilkingSummaryConfirm));
export {forExport as MilkingSummaryConfirm};
