import * as React from 'react';
import {useState, useEffect} from 'react';
// import {useLocation} from 'react-router-dom';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import {sessionState} from 'session/session-state.js';
import {DateUtil} from 'utils/date-util.js';
import {FarmSelect} from 'components/FarmSelect.js';
import {instantTakeStatusAPI} from 'api/instant-rake-status.js';

import {GlobalCss} from 'components/global-css.js';
import {DatePickerWrapper} from 'components/DatePickerWrapper.js';
import {ErrorMessage} from 'components/ErrorMessage.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
};

const JOB_NAME = 'InstantRake-milking_summary:specified_period';

const MilkingSummaryIndex = (props) => {
  const initialState = props.location.state;

  const initialJobStatus = {
    status: '処理待ち',
    startAt: '',
    endAt: '',
    executeCount: '',
  };

  const [selectedFarm, setSelectedFarm] = useState(sessionState.currentFarm());
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(DateUtil.today());
  const [jobStatus, setJobStatus] = useState(initialJobStatus);
  const [errorMessage, setErrorMessage] = useState('');

  const onDateChange = (value, func) => {
    func(DateUtil.startOfDay(value));
  };

  const createParams = () => {
    return {
      pathname: '/milking_summary/confirm',
      state: {
        farm: selectedFarm,
        fromDate: DateUtil.toMSec(fromDate),
        toDate: DateUtil.toMSec(toDate),
      }
    };
  };

  const createAction = () => {
    const result = validate();
    if (!result.valid) {
      setErrorMessage(result.errors.join('\n'));
      return;
    } else {
      setErrorMessage('');
    }
    props.history.push(createParams());
  };

  const validate = () => {
    const errors = [];

    if (!fromDate) {
      errors.push('開始日が指定されていません。');
    }

    if (fromDate) {
      if (DateUtil.isFutureDay(fromDate)) {
        errors.push('開始日は未来の日付を指定できません。');
      }
    }

    return {valid: errors.length === 0, errors: errors};
  };

  const pickupMilkingFarms = (famrs) => {
    return famrs.filter((farm) => farm.isMilk());
  };

  const refreshStatus = (initialStatus = null) => {
    const toStatusLabel = (status) => {
      switch (status) {
      case 'success': {
        return '完了';
      }
      case 'proccessing': {
        return '処理中';
      }
      case 'error': {
        return 'エラー';
      }
      case 'abend': {
        return '異常終了';
      }
      case 'waiting': {
        return '処理待ち';
      }
      default: {
        return '';
      }
      }
    };

    return instantTakeStatusAPI.show(JOB_NAME).then((res) => {
      const record = res.data;
      const jobStatus = {
        status: toStatusLabel(record.status),
        startAt: DateUtil.toJapaneseYYYYMMDDHHmm(record.startAt),
        endAt: DateUtil.toJapaneseYYYYMMDDHHmm(record.endAt),
        executeCount: record.status === 'success' ? record.executeCount : '',
      };

      setJobStatus(jobStatus);
    });
  };

  useEffect(() => {
    if (initialState && initialState.waitingJob) {
      const record = {
        status: '処理待ち',
        startAt: '',
        endAt: '',
        executeCount: '',
      };
      setJobStatus(record);
    } else {
      refreshStatus();
    }
    // ※ESlintの余分な警告を回避するための措置
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{marginLeft: '20px'}}>
      <h2>搾乳量再集計</h2>

      <div style={{marginLeft: '40px'}}>
        <ErrorMessage errorMessage={errorMessage} />
      </div>

      <ul className={props.classes.flexContainer}>
        <li>
          <FarmSelect
            changeAction={() => {
              setSelectedFarm(sessionState.currentFarm());
            }}
            filterFunction={(farms) => pickupMilkingFarms(farms)}
          />
        </li>
      </ul>

      <ul className={props.classes.flexContainer} style={{marginLeft: 40, marginBottom: 20}}>
        <li style={{width: 150, marginRight: 50}}>
          <DatePickerWrapper
            label="開始日"
            date={fromDate}
            margin="none"
            syncState={(value) => onDateChange(value, setFromDate)}
          />
        </li>

        <li style={{width: 150}}>
          <DatePickerWrapper
            label="終了日"
            date={toDate}
            margin="none"
            syncState={(value) => onDateChange(value, setToDate)}
          />
        </li>

        <li style={{marginTop: 10, marginLeft: 50}}>
          <Button variant="contained" color="primary" onClick={() => createAction()}>
            実行
          </Button>
        </li>
      </ul>

      <div style={{marginTop: 50, marginLeft: 50}}>
        <div style={{fontSize: 20, marginLeft: 30}}>
          処理状況
        </div>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 120, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>ステータス:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={jobStatus && jobStatus.status}
              disabled={true}
              margin="none"
              style={{width: 80}}
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 120, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>開始時刻:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={jobStatus && jobStatus.startAt}
              disabled={true}
              margin="none"
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 120, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>終了時刻:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={jobStatus && jobStatus.endAt}
              disabled={true}
              margin="none"
            />
          </li>
        </ul>

        <ul className={props.classes.flexContainer} style={{marginBottom: 10}}>
          <li style={{width: 120, marginTop: 10}}>
            <InputLabel style={{fontWeight: 'bold', marginTop: 5}}>処理件数:</InputLabel>
          </li>
          <li style={{paddingTop: 5}}>
            <TextField
              type="text"
              required={true}
              placeholder=""
              value={jobStatus && jobStatus.executeCount}
              disabled={true}
              margin="none"
              style={{width: 80}}
            />
          </li>
        </ul>

        <div style={{marginTop: 30, marginLeft: 220}}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => refreshStatus()}
          >
            処理状況更新
          </Button>
        </div>
      </div>
    </div>
  );
};

MilkingSummaryIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(MilkingSummaryIndex));
export {forExport as MilkingSummaryIndex};
