import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {BrowserRouter, Route, Redirect, Switch, Link} from 'react-router-dom';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import HomeIcon from '@material-ui/icons/Home';
import ExitToaAppIcon from '@material-ui/icons/ExitToApp';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventIcon from '@material-ui/icons/Event';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import Divider from '@material-ui/core/Divider';
import GridOffIcon from '@material-ui/icons/GridOff';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SignalCellularNoSim from '@material-ui/icons/SignalCellularNoSim';
import SyncIcon from '@material-ui/icons/Sync';

import {ErrorMessage} from 'components/ErrorMessage.js';
import {LoginForm} from 'components/forms/LoginForm.js';
import {Logout} from 'components/Logout.js';
import {HomeForm} from 'components/forms/HomeForm.js';
import {FarmIndex} from 'components/forms/farm/FarmIndex.js';
import {FarmEdit} from 'components/forms/farm/FarmEdit.js';
import {CowshedIndex} from 'components/forms/cowshed/CowshedIndex.js';
import {CowshedEdit} from 'components/forms/cowshed/CowshedEdit.js';
import {CowGroupIndex} from 'components/forms/cow-group/CowGroupIndex.js';
import {CowGroupEdit} from 'components/forms/cow-group/CowGroupEdit.js';
import {AccountIndex} from 'components/forms/account/AccountIndex.js';
import {AccountEdit} from 'components/forms/account/AccountEdit.js';
import {AlertMailIndex} from 'components/forms/alert-mail/AlertMailIndex.js';
import {AlertMailEdit} from 'components/forms/alert-mail/AlertMailEdit.js';
import {CowEventIndex} from 'components/forms/cow-event/CowEventIndex.js';
import {CowEventImportIndex} from 'components/forms/cow-event-import/CowEventImportIndex.js';
import {ActionLogIndex} from 'components/forms/action-log/ActionLogIndex.js';
import {ActionLogDetail} from 'components/forms/action-log/ActionLogDetail.js';
import {MasterImportIndex} from 'components/forms/master-import/MasterImportIndex.js';
import {CowDeleteIndex} from 'components/forms/cow-delete/CowDeleteIndex.js';
import {CalfDeleteIndex} from 'components/forms/calf-delete/CalfDeleteIndex.js';
import {NotificationIndex} from 'components/forms/notification/NotificationIndex.js';
import {NotificationEdit} from 'components/forms/notification/NotificationEdit.js';
import {AuthorizedFarmIndex} from 'components/forms/authorized-farm/AuthorizedFarmIndex.js';
import {AuthorizedFarmEdit} from 'components/forms/authorized-farm/AuthorizedFarmEdit.js';
import {FarmMovingIndex} from 'components/forms/farm-moving/FarmMovingIndex.js';
import {PushLogIndexScreen} from 'screens/PushLogIndexScreen';
import {UClipsIndexScreen} from 'screens/UClipsScreen/index.jsx';
import {UClipsEditScreen} from 'screens/UClipsScreen/edit.jsx';
import {ActivityImportRetryIndex} from 'components/forms/activity-import-retry/ActivityImportRetryIndex';
import {ActivityImportRetryConfirm} from 'components/forms/activity-import-retry/ActivityImportRetryConfirm';
import {EartagAnalyzerIndex} from 'components/forms/eartag-analyzer/EartagAnalyzerIndex';
import {EartagAnalyzerConfirm} from 'components/forms/eartag-analyzer/EartagAnalyzerConfirm';
import {EcsTasksIndex} from 'components/forms/ecs-tasks/EcsTasksIndex';
import {EcsTasksConfirm} from 'components/forms/ecs-tasks/EcsTasksConfirm';
import {MilkingSummaryIndex} from 'components/forms/milking-summary/MilkingSummaryIndex';
import {MilkingSummaryConfirm} from 'components/forms/milking-summary/MilkingSummaryConfirm';

const styles = {
  flexContainer: {
    display: 'flex',
    listStyle: 'none'
  },
  sideMenu: {
    width: 350
  },
  mainContent: {
    width: '100%',
    overflow: 'auto'
  },
};

const Routing = (props) => {
  const classes = props.classes;
  const env = process.env.REACT_APP_ENVIRONMENT || 'local';
  const envColor = env === '本番' ? '#F08080' : '#2E8B57';

  return (
    <BrowserRouter>
      <div>
        <ul className={classes.flexContainer} style={{paddingLeft: '5px'}}>
          { props.authenticated ?
            <li className={classes.sideMenu}>
              <span style={{marginLeft: 20, fontWeight: 'bold', color: envColor}}>
                [{env}環境]
              </span>

              <AuthenticatedMenus />
            </li>
            : null }

          <li className={classes.mainContent}>
            <ErrorMessage errorMessage={props.errorMessage} />

            { props.authenticated ? <AuthenticatedRouting /> : <NotAuthenticatedRouting syncState={props.syncState} /> }
          </li>
        </ul>
      </div>
    </BrowserRouter>
  );
};

Routing.propTypes = {
  authenticated: PropTypes.bool,
  syncState: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const forExport = withStyles(styles)(Routing);
export {forExport as Routing};

const AuthenticatedMenus = () => {
  return (
    <List>
      <ListItem button component={Link} to="/">
        <ListItemIcon><PublicIcon /></ListItemIcon>
        <ListItemText primary="ホーム" />
      </ListItem>

      <ListItem button component={Link} to="/farm">
        <ListItemIcon><LocationCityIcon /></ListItemIcon>
        <ListItemText primary="牧場" />
      </ListItem>

      <ListItem button component={Link} to="/cowshed">
        <ListItemIcon><HomeIcon /></ListItemIcon>
        <ListItemText primary="牛舎" />
      </ListItem>

      <ListItem button component={Link} to="/cow_group">
        <ListItemIcon><ViewModuleIcon /></ListItemIcon>
        <ListItemText primary="牛群" />
      </ListItem>

      <ListItem button component={Link} to="/account">
        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
        <ListItemText primary="アカウント" />
      </ListItem>

      <ListItem button component={Link} to="/alert_mail">
        <ListItemIcon><ContactMailIcon /></ListItemIcon>
        <ListItemText primary="アラートメール設定" />
      </ListItem>

      <ListItem button component={Link} to="/action_log">
        <ListItemIcon><AvTimerIcon /></ListItemIcon>
        <ListItemText primary="操作履歴" />
      </ListItem>

      <ListItem button component={Link} to="/cow_event">
        <ListItemIcon><EventIcon /></ListItemIcon>
        <ListItemText primary="イベントデータ" />
      </ListItem>

      <ListItem button component={Link} to="/cow_event_import">
        <ListItemIcon><PlaylistAddIcon /></ListItemIcon>
        <ListItemText primary="イベント一括編集" />
      </ListItem>

      <ListItem button component={Link} to="/master_import">
        <ListItemIcon><PermDataSettingIcon /></ListItemIcon>
        <ListItemText primary="マスター一括編集" />
      </ListItem>

      <ListItem button component={Link} to="/cow_delete">
        <ListItemIcon><GridOffIcon /></ListItemIcon>
        <ListItemText primary="牛個体一括削除" />
      </ListItem>

      <ListItem button component={Link} to="/calf_delete">
        <ListItemIcon><GridOffIcon /></ListItemIcon>
        <ListItemText primary="子牛一括削除" />
      </ListItem>

      <ListItem button component={Link} to="/milking_summary">
        <ListItemIcon><SyncIcon /></ListItemIcon>
        <ListItemText primary="搾乳量再集計" />
      </ListItem>

      <ListItem button component={Link} to="/activity_import_retry">
        <ListItemIcon><SignalCellularNoSim /></ListItemIcon>
        <ListItemText primary="活動量再取込" />
      </ListItem>

      <ListItem button component={Link} to="/eartag_analyzer">
        <ListItemIcon><SignalCellularNoSim /></ListItemIcon>
        <ListItemText primary="[イヤタグ] 活動量再分析" />
      </ListItem>

      <ListItem button component={Link} to="/ecs_tasks">
        <ListItemIcon><SignalCellularNoSim /></ListItemIcon>
        <ListItemText primary="ECS タスク実行" />
      </ListItem>

      <ListItem button component={Link} to="/notification">
        <ListItemIcon><NotificationsIcon /></ListItemIcon>
        <ListItemText primary="お知らせ" />
      </ListItem>

      <ListItem button component={Link} to="/authorized_farm">
        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
        <ListItemText primary="牧場アクセス権" />
      </ListItem>

      <ListItem button component={Link} to="/farm_moving">
        <ListItemIcon><TrendingFlat /></ListItemIcon>
        <ListItemText primary="牧場異動(暫定機能)" />
      </ListItem>

      <ListItem button component={Link} to="/push_log">
        <ListItemIcon><ListAltIcon /></ListItemIcon>
        <ListItemText primary="プッシュ通知送信ログ" />
      </ListItem>

      <ListItem button component={Link} to="/u_clips">
        <ListItemIcon><SignalCellularNoSim /></ListItemIcon>
        <ListItemText primary="U-Clips" />
      </ListItem>

      <Divider />

      <ListItem button component={Link} to="/logout">
        <ListItemIcon><ExitToaAppIcon /></ListItemIcon>
        <ListItemText primary="ログアウト" />
      </ListItem>
    </List>
  );
};

const AuthenticatedRouting = () => {
  return (
    <Switch>
      <Route path="/" exact component={HomeForm} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/farm" exact component={FarmIndex} />
      <Route path="/farm/:action" exact component={FarmEdit} />
      <Route path="/farm/:id/:action" exact component={FarmEdit} />
      <Route path="/cowshed" exact component={CowshedIndex} />
      <Route path="/cowshed/:action" exact component={CowshedEdit} />
      <Route path="/cowshed/:id/:action" exact component={CowshedEdit} />
      <Route path="/cow_group" exact component={CowGroupIndex} />
      <Route path="/cow_group/:action" exact component={CowGroupEdit} />
      <Route path="/cow_group/:id/:action" exact component={CowGroupEdit} />
      <Route path="/account" exact component={AccountIndex} />
      <Route path="/account/:action" exact component={AccountEdit} />
      <Route path="/account/:id/:action" exact component={AccountEdit} />
      <Route path="/alert_mail" exact component={AlertMailIndex} />
      <Route path="/alert_mail/edit" exact component={AlertMailEdit} />
      <Route path="/action_log" exact component={ActionLogIndex} />
      <Route path="/action_log/detail" exact component={ActionLogDetail} />
      <Route path="/cow_event" exact component={CowEventIndex} />
      <Route path="/cow_event_import" exact component={CowEventImportIndex} />
      <Route path="/master_import" exact component={MasterImportIndex} />
      <Route path="/cow_delete" exact component={CowDeleteIndex} />
      <Route path="/calf_delete" exact component={CalfDeleteIndex} />
      <Route path="/notification" exact component={NotificationIndex} />
      <Route path="/notification/:action" exact component={NotificationEdit} />
      <Route path="/notification/:id/:action" exact component={NotificationEdit} />
      <Route path="/authorized_farm" exact component={AuthorizedFarmIndex} />
      <Route path="/authorized_farm/:action" exact component={AuthorizedFarmEdit} />
      <Route path="/authorized_farm/:id/:action" exact component={AuthorizedFarmEdit} />
      <Route path="/farm_moving" exact component={FarmMovingIndex} />
      <Route path="/push_log" exact component={PushLogIndexScreen} />
      <Route path="/u_clips" exact component={UClipsIndexScreen} />
      <Route path="/u_clips/:action" exact component={UClipsEditScreen} />
      <Route path="/u_clips/:id/:action" exact component={UClipsEditScreen} />
      <Route path="/activity_import_retry" exact component={ActivityImportRetryIndex} />
      <Route path="/activity_import_retry/confirm" exact component={ActivityImportRetryConfirm} />
      <Route path="/eartag_analyzer" exact component={EartagAnalyzerIndex} />
      <Route path="/eartag_analyzer/confirm" exact component={EartagAnalyzerConfirm} />
      <Route path="/ecs_tasks" exact component={EcsTasksIndex} />
      <Route path="/ecs_tasks/confirm" exact component={EcsTasksConfirm} />
      <Route path="/milking_summary" exact component={MilkingSummaryIndex} />
      <Route path="/milking_summary/confirm" exact component={MilkingSummaryConfirm} />
      <Route component={HomeForm} />
    </Switch>
  );
};

const NotAuthenticatedRouting = (props) => {
  const currentPath = window.location.pathname;

  return (
    <Switch>
      <Route path="/login" render={() => <LoginForm originPath={currentPath} syncState={props.syncState} />} />
      <Route path='*'
        render={() => (
          <Redirect to="/login" />
        )}
      />
    </Switch>
  );
};

NotAuthenticatedRouting.propTypes = {
  syncState: PropTypes.func.isRequired,
};
